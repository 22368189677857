import {DndContext, DragEndEvent} from '@dnd-kit/core';
import React from 'react';
import {Droppable} from "../Droppable";
import {Draggable} from "../Draggable";


export interface ImportDataColumn {
    Id: string,
    longitudeId: string,
    latitudeId: string,
    nameId: string,
    valueId?: string,
}

export interface DragDropProps {
    initialDataColumns?: string[],
    importDataColumns?: ImportDataColumn,
    exportDataColumns?: string[],
    handleDragDropChange?: (event: DragEndEvent) => void,
    importDataColumnsObject: IImportDataColumnsObject[];
}

export enum ImportDataColumnEnum {
    ID = "Id",
    LONGITUDE_ID = "longitudeId",
    LATITUDE_ID = "latitudeId",
    NAME_ID = "nameId",
    VALUES_ID = "valueId",
}

export interface IImportDataColumnsObject {
    key: ImportDataColumnEnum,
    label: string,
    value?: { id: string },
}

function DragDrop(dragDropProps: DragDropProps) {

    return <div style={{display: "flex",}}>
        <div style={{width: '300px'}}>
            <DndContext onDragEnd={dragDropProps.handleDragDropChange}>
                <div style={{display: "flex",}}>
                    <div style={{margin: "10px"}}>
                        {dragDropProps.importDataColumnsObject.map((importData) =>
                            <Droppable key={importData.key} id={importData.key}>
                                {importData.label}: {importData.value?.id}
                            </Droppable>)}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginLeft: "10px"}}>
                        {dragDropProps.initialDataColumns?.map(dataColumn =>
                            <Draggable key={dataColumn} id={dataColumn}>
                                {dataColumn}
                            </Draggable>
                        )}
                    </div>
                </div>
            </DndContext>
        </div>

    </div>;
}

export default DragDrop;
