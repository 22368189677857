import React from "react";
import Graph from "graphology";
import {Attributes} from "graphology-types";
import {IImportDataColumnsObject, ImportDataColumnEnum} from "../components/dragDrop/dragDrop";
import {normalEdgeColor} from "../App";
import {PCG} from 'random-seedable'

export function graphWholeReset(graphRef: React.MutableRefObject<Graph<Attributes, Attributes, Attributes>>,
                                data: any,
                                haversineDistance: (coord1: any, coord2: any) => number,
                                value: number,
                                allowedConnectionNumber: number,
                                startNode: string | null | undefined,
                                endNode: string | null | undefined,
                                importDataColumnsObjects: IImportDataColumnsObject[],
                                useValueProp: boolean,
                                isRandomized: boolean,
                                visibleValue: number,
                                seed: number,
                                randomizeInfluence: number) {
    let i;
    graphRef.current.clear();
    const idColumnIdKey = importDataColumnsObjects.find(importDataColumn => importDataColumn.key === ImportDataColumnEnum.ID)?.value?.id;
    const latitudeIdKey = importDataColumnsObjects.find(importDataColumn => importDataColumn.key === ImportDataColumnEnum.LATITUDE_ID)?.value?.id;
    const longitudeIdKey = importDataColumnsObjects.find(importDataColumn => importDataColumn.key === ImportDataColumnEnum.LONGITUDE_ID)?.value?.id;
    const valueIdKey = importDataColumnsObjects.find(importDataColumn => importDataColumn.key === ImportDataColumnEnum.VALUES_ID)?.value?.id;
    const nameIdKey = importDataColumnsObjects.find(importDataColumn => importDataColumn.key === ImportDataColumnEnum.NAME_ID)?.value?.id;


    for (i = 0; i < data.length; i++) {
        graphRef.current.addNode(data[i][idColumnIdKey!!], {
            size: 4,
            label: data[i][nameIdKey!!],
            x: data[i][longitudeIdKey!!],
            y: data[i][latitudeIdKey!!],
            value: data[i][valueIdKey!!],
            forceLabel: useValueProp && visibleValue < data[i][valueIdKey!!],
        });
    }
    if (isRandomized) {
        const random = new PCG(seed);
        for (i = 0; i < data.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (
                    i !== j &&
                    !(graphRef.current.edge(data[i][idColumnIdKey!!], data[j][idColumnIdKey!!]) || graphRef.current.edge(data[j][idColumnIdKey!!], data[i][idColumnIdKey!!]))
                ) {
                    const distance = haversineDistance(
                        {latitude: data[i][latitudeIdKey!!], longitude: data[i][longitudeIdKey!!]},
                        {latitude: data[j][latitudeIdKey!!], longitude: data[j][longitudeIdKey!!]},
                    );
                    if (
                        distance < value &&
                        (graphRef.current.degree(data[i][idColumnIdKey!!]) < allowedConnectionNumber ||
                            graphRef.current.degree(data[j][idColumnIdKey!!]) < allowedConnectionNumber) &&
                        random.coin(randomizeInfluence)
                    ) {
                        graphRef.current.addEdgeWithKey(`${data[i][idColumnIdKey!!]};${data[j][idColumnIdKey!!]}`, data[i][idColumnIdKey!!], data[j][idColumnIdKey!!], {size: 2});
                    }
                }
            }
        }
    } else {
        for (i = 0; i < data.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (
                    i !== j &&
                    !(graphRef.current.edge(data[i][idColumnIdKey!!], data[j][idColumnIdKey!!]) || graphRef.current.edge(data[j][idColumnIdKey!!], data[i][idColumnIdKey!!]))
                ) {
                    const distance = haversineDistance(
                        {latitude: data[i][latitudeIdKey!!], longitude: data[i][longitudeIdKey!!]},
                        {latitude: data[j][latitudeIdKey!!], longitude: data[j][longitudeIdKey!!]},
                    );
                    if (
                        distance < value &&
                        (graphRef.current.degree(data[i][idColumnIdKey!!]) < allowedConnectionNumber ||
                            graphRef.current.degree(data[j][idColumnIdKey!!]) < allowedConnectionNumber)
                    ) {
                        graphRef.current.addEdgeWithKey(`${data[i][idColumnIdKey!!]};${data[j][idColumnIdKey!!]}`, data[i][idColumnIdKey!!], data[j][idColumnIdKey!!], {size: 2});
                    }
                }
            }
        }
    }
    let maxDistance = 0;
    let minDistance = Infinity;

    for (let i = 0; i < data.length; i++) {
        for (let j = i + 1; j < data.length; j++) {
            const distance = haversineDistance(
                {latitude: data[i][latitudeIdKey!!], longitude: data[i][longitudeIdKey!!]},
                {latitude: data[j][latitudeIdKey!!], longitude: data[j][longitudeIdKey!!]},
            );
            if (distance > maxDistance) {
                maxDistance = distance;
            }
            if (distance < minDistance) {
                minDistance = distance;
            }
        }
    }
    if (startNode) {
        graphRef.current.setNodeAttribute(startNode, "color", "yellow")
    }
    if (endNode) {
        graphRef.current.setNodeAttribute(endNode, "color", "blue")
    }
    return {maxDistance, minDistance};
}

export function clearGraph(graphRef: React.MutableRefObject<Graph>, startNode: string | null | undefined, endNode: string | null | undefined) {
    // eslint-disable-next-line array-callback-return
    graphRef.current.nodes().map(node => {
        for (const neighbour of graphRef.current.neighbors(node)) {
            graphRef.current.setEdgeAttribute(node, neighbour, 'color', normalEdgeColor);
        }
        graphRef.current.setNodeAttribute(node, 'color', 'green');
        if (node === startNode) {
            graphRef.current.setNodeAttribute(startNode, 'color', 'yellow');
        }
        if (node === endNode) {
            graphRef.current.setNodeAttribute(endNode, 'color', 'blue');
        }
    })
}
